@import "~@fortawesome/fontawesome-free/css/all.css";
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
* {
  box-sizing: border-box;
  direction: rtl;
}
body {
  font-family: 'Calibri', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  overflow-x: hidden;
  width: 100vw;
}
button {
  font-family : inherit;
}
.custom-dialog-container .mat-dialog-container {
  padding: 0px;
}

.wColorPicker-theme-classic,.wPaint-menu-icon-name-lineWidth{
  display: none;
}
.wPaint-text-input,.wPaint-canvas{
  direction: ltr;
}

